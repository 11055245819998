import { TRACK_EVENTS } from "core/consts";
import { getKey } from "core/model/utils/strings";
import { renderMultiLine } from "core/model/utils/text";
import { KeyboardArrowRightIcon } from "ds/icons";
import LinkComponent from "ds_legacy/components/Link";
import { MESSAGING_BUBBLE_BACKGROUND_LIGHT } from "ds_legacy/materials/colors";
import { margin, sizing } from "ds_legacy/materials/metrics";
import { Body, Caption, FONT_SIZE_12 } from "ds_legacy/materials/typography";
import { EventSpecMessage } from "dsl/atoms/EventSpecs";
import React, { CSSProperties } from "react";
import { To, useNavigate } from "react-router-dom";
import { useTracking } from "react-tracking";
import styled from "styled-components";

const BubbleContainer = styled.div<{
  column?: boolean;
  right?: boolean;
  withMargin?: boolean;
}>`
  position: relative;
  width: 100%;
  display: flex;
  flex: 1 1 0px;
  flex-direction: ${(props) => (props.column ? "column" : "row")};
  justify-content: ${(props) => (props.right ? "flex-end" : "flex-start")};
  align-items: ${(props) =>
    props.column && !props.right ? "flex-start" : "flex-end"};
  opacity: 1;
  margin-bottom: ${(props) => (props.withMargin ? "7px" : undefined)};
`;

const BubbleBody = styled.div<{ bubble?: boolean; right?: boolean }>`
  min-width: 30%;
  /* until chrome fixes the flex-end % width bug */
  /* we cannot align right w/ a dynamic width */
  align-items: flex-start;
  margin: ${({ right }) => (right ? margin(0, 0, 0, 1) : margin(0, 1, 0, 0))};
  border-radius: 8px 8px
    ${({ bubble, right }) =>
      bubble ? "8px 8px" : right ? "0px 8px" : "8px 0px"};
  background-color: ${MESSAGING_BUBBLE_BACKGROUND_LIGHT};
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const BodyWrapper = styled(Body)`
  margin: ${margin(1, 1.5)};
  word-wrap: break-word;
  max-width: 100%;
`;

export function Bubble({
  blur,
  body,
  bubble,
  right,
  style,
  textAlignRight,
}: {
  blur?: boolean;
  body: React.ReactNode | null | undefined;
  bubble?: boolean;
  right?: boolean;
  style?: React.CSSProperties;
  textAlignRight?: boolean;
}) {
  return (
    <BubbleBody right={right} bubble={bubble} style={style}>
      {blur ? (
        <div style={{ height: sizing(5), width: sizing(22.5) }} />
      ) : (
        <BodyWrapper textAlign={textAlignRight ? "right" : "left"}>
          {typeof body === "string" ? renderMultiLine(body) : body}
        </BodyWrapper>
      )}
    </BubbleBody>
  );
}

export function MessengerSpecMessage({
  eventMessage,
  textAlign,
  whiteSpace,
}: {
  eventMessage: Exclude<EventSpecMessage, string> | undefined;
  textAlign?: CSSProperties["textAlign"];
  whiteSpace?: CSSProperties["whiteSpace"];
}) {
  const { trackEvent } = useTracking();
  const navigate = useNavigate();
  if (!eventMessage || typeof eventMessage === "string") return null;

  return (
    <>
      {eventMessage.title && (
        <Body margin={margin(0)} textAlign={textAlign} whiteSpace="pre-wrap">
          {eventMessage.title}
        </Body>
      )}
      {eventMessage.date && (
        <Caption
          margin={margin(0, 1.25, 0, 1.25)}
          textAlign={textAlign}
          whiteSpace={whiteSpace}
        >
          {eventMessage.date}
        </Caption>
      )}
      {eventMessage.date && eventMessage.time && (
        <Caption
          margin={margin(0, 1.25, 0, 1.25)}
          textAlign={textAlign}
          whiteSpace={whiteSpace}
        >
          {eventMessage.time}
        </Caption>
      )}
      {eventMessage.reasons?.map((reason, i) => {
        return (
          <Body
            key={getKey(reason, i)}
            margin={margin(0)}
            textAlign={textAlign}
            whiteSpace="pre-wrap"
          >
            {reason}
          </Body>
        );
      })}
      {eventMessage.link && (
        <LinkComponent
          fontSize={FONT_SIZE_12}
          Icon={KeyboardArrowRightIcon}
          value={eventMessage.link.label}
          onAction={() => {
            trackEvent({
              name: TRACK_EVENTS.MESSENGER_SEE_DOCUMENT_LINK,
            });
            navigate(eventMessage.link?.to as To);
          }}
        />
      )}
    </>
  );
}

export function EventMessage({
  eventMessages,
  right,
}: {
  eventMessages?: EventSpecMessage[];
  right?: boolean;
}) {
  if (!eventMessages) return null;

  return (
    <>
      {eventMessages?.map((eventMessage, idx) => (
        <BubbleContainer key={idx.toString()} right={right} withMargin column>
          <MessengerSpecMessage
            eventMessage={eventMessage}
            textAlign={right ? "right" : "left"}
          />
        </BubbleContainer>
      ))}
    </>
  );
}
