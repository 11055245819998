import { decryptFile } from "core/model/crypto/cryptoService";
import { downloadFile } from "core/model/files";
import { FileMimeType, mimeTypeToExtensionMap } from "core/model/files/config";
import { decryptFromSession } from "core/seald/sessions";
import { Filev1 } from "core/types";
import { Iterable } from "immutable";
import { convertModelDefinition, valueDef } from "react-forms-state";

export function isValidFile(file: Filev1): boolean {
  const { iv, link, name, type } = file || {};
  return name != null && iv != null && type != null && link != null;
}

/**
 * @deprecated only used in the EventCardV2 with DownloadButton (not used anymore),
 * the insurance flow for uploading rehab files (not used anymore), and the
 * native application (pending decision)
 */
export async function decrypt({
  content,
  decryptedSessionKey,
  file,
  seald_content,
}: {
  content: string;
  decryptedSessionKey: CryptoKey;
  file: Filev1;
  seald_content: string | undefined;
}) {
  const { encrypted_name, iv, name, type } = file;
  if (!iv || !type) {
    console.error("no iv in file object", name);
    return null;
  }

  // seald decrypt
  const sealdDecryptedFileB64 = await decryptFromSession({
    encryptedMessage: seald_content,
    context: "file",
  });
  if (sealdDecryptedFileB64 && name) {
    const sealdDecryptedFile = Buffer.from(sealdDecryptedFileB64, "base64");
    console.log("decryptedBySeald", { name, check: !!sealdDecryptedFile });
  }

  try {
    const decrypted = await decryptFile(content, decryptedSessionKey, iv);
    const fileName =
      encrypted_name == null
        ? name
        : encrypted_name?.decrypted?.concat(
            mimeTypeToExtensionMap[type as FileMimeType],
          );
    return downloadFile({
      content: decrypted,
      fileName: fileName || "",
      type,
    });
  } catch (error) {
    console.error("Could not decrypt file");
  }
}

export const fileUploadModelDefinition = convertModelDefinition({
  ...valueDef("title", {
    defaultValue: "",
    validate: (v) => !!v,
  }),
  ...valueDef("content", {
    defaultValue: {},
    validate: (v) => (Iterable.isIterable(v) ? !!v.get("data") : !!v?.data),
  }),
});

export function fileConvertOut(value: any) {
  if (!value) return {};
  const { content, title } = value;

  return { title, ...content };
}

export type FileUploadValue = {
  data?: string;
  name?: string;
  type?: string;
};
